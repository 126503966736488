import { useEffect } from 'react';

export const localStorageSet = (key, value) => {
    if (typeof window !== 'undefined') window.localStorage.setItem(key, JSON.stringify(value));
};

export const localStorageGet = (key) => ((typeof window !== 'undefined') ? JSON.parse(window.localStorage.getItem(key)) : undefined);
export const localStorageRemove = (key) => ((typeof window !== 'undefined') ? window.localStorage.removeItem(key) : undefined);

const useLocalStorageSync = (key, state) => {
    useEffect(() => {
        localStorageSet(key, state);
    }, [state]);
};

export default useLocalStorageSync;
