import React, { useEffect } from 'react';

import Layout from '@layouts/Layout';

import BlogCard from '@components/_ui/BlogCard';
import ContactCallout from '@components/callouts/ContactCallout';
import { localStorageRemove } from '../../../hooks/useLocalStorageSync';

const OrderConfirmed = () => {
    useEffect(() => {
        localStorageRemove('basket_session_id');
    }, []);
    return (
        <Layout metaData={{ title: 'Order Confirmed' }}>
            <header className="hero background-color--chalk ">
                <div className="container flow text-align--center">
                    <h1 className="text-page-title">Authorisation received</h1>
                </div>
            </header>
            <section className="container backdrop backdrop--top-half">
                Authorisation received
            </section>
            <section className="wrapper-top--default">
                <div className="container flow--medium">
                    <div>
                        <h2 className="text-subtitle">Living outdoors</h2>
                        <p className="text-paragraph text-color--steelbook">Find out how to get the most out of your pergola with our guides.</p>
                    </div>
                    <div>
                        <div className="grid-x grid-margin-x grid-margin-y">
                            <div className="cell medium-6 large-4">
                                <BlogCard
                                    title="Pergola Planting: Annual Climbers in Pots"
                                    postedAt="3rd August 2019"
                                    imgSrc="/img/outdoor-living/pergola-planting-annual-climbers-in-pots/cupandsaucervine-rhs.jpg"
                                    imgAlt="The purple flowers of a cup-and-saucer vine (cobaea scandens AGM)"
                                    url="/news/pergola-planting-annual-climbers-in-pots/"
                                />
                            </div>
                            <div className="cell medium-6 large-4">
                                <BlogCard
                                    title="Karen’s Spring Blog - Edible Hanging Baskets"
                                    postedAt="15th August 2019"
                                    url="/news/karens-spring-blog-edible-hanging-baskets/"
                                    imgSrc="/img/outdoor-living/edible-hanging-baskets/tomato-hanging-basket.jpg"
                                />
                            </div>
                            <div className="cell medium-6 large-4">
                                <BlogCard
                                    title="Scented Climbers for Pergolas"
                                    postedAt="15th May 2019"
                                    url="/news/scented-climbers-for-pergolas"
                                    imgSrc="/img/outdoor-living/scented-climbers-for-pergolas/trachelospermum.jpg"
                                />
                            </div>
                            <div className="cell medium-6 large-4">
                                <BlogCard
                                    title="How to revamp wooden outdoor furniture"
                                    postedAt="5th January 2019"
                                    imgSrc="/img/outdoor-living/how-to-revamp-wooden-outdoor-furniture/Cuprinol_(8).jpg"
                                    imgAlt="An outdoor seating area underneath a pergola, with an array of wicker garden seating and a barbeque"
                                    url="/news/how-to-revamp-wooden-outdoor-furniture/"
                                />
                            </div>
                            <div className="cell medium-6 large-4">
                                <BlogCard
                                    title="Pergola Planting: Climbing Vegetables"
                                    postedAt="4th February 2019"
                                    url="/news/pergola-planting-climbing-vegetables/"
                                    imgSrc="/img/outdoor-living/pergola-planting-climbing-vegetables/Runner-bean-pods-RHS.jpg"
                                    imgAlt="A group of runner bean pods ready for picking"
                                />
                            </div>
                            <div className="cell medium-6 large-4">
                                <BlogCard
                                    title="5 ways to embrace the Mediterranean lifestyle in your back garden"
                                    postedAt="3rd March 2010"
                                    url="/news/5-ways-to-embrace-the-mediterranean-lifestyle-in-your-back-garden/"
                                    imgSrc="/img/outdoor-living/5-ways-to-embrace-the-mediterranean-lifestyle-in-your-back-garden/download.jpg"
                                    imgAlt="A long outdoor dining table set with a lunch spread for six places"
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </section>
            <section className="wrapper--medium container">
                <ContactCallout />
            </section>
        </Layout>
    );
};

export default OrderConfirmed;
